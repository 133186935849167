import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "../../../components/shared/Container";
import {
  DatoCmsContentBase,
  DatoCmsContentMediaBlock,
  DatoCmsListBlock,
} from "../../types";

import { StructuredText } from "react-datocms";
import ReactMarkdown from "react-markdown";

type Props = {
  section: DatoCmsContentMediaBlock | DatoCmsListBlock | DatoCmsContentBase;
};

export default function DefaultTemplate({ section }: Props): JSX.Element {
  const { boats }: { boats: { edges: { node: any }[] } } = useStaticQuery(
    graphql`
      query {
        boats: allDatoCmsBoat {
          edges {
            node {
              id
              name
              boatModel
              image
              comingsoon
            }
          }
        }
      }
    `
  );

  const text = section?.stringContent ? (
    <ReactMarkdown>{section?.stringContent}</ReactMarkdown>
  ) : (
    <>
      {section?.content?.value ? (
        <StructuredText data={section.content} />
      ) : null}
    </>
  );

  return (
    <Container>
      <div>
        <h2 className="inline-block text-2xl font-bold md:text-4xl mb-2 border-b-4 border-primary">
          {section.title}
        </h2>
        <h3 className="text-xl md:text-2xl mb-4">{section.subTitle}</h3>
      </div>
      {text ? <div className="mt-2">{text}</div> : null}

      <div className="flex flex-wrap items-start -mx-3">
        {boats.edges.map(({ node: boat }) => (
          <div className="px-3 w-full md:w-1/5" key={boat.id}>
            <div
              className="relative bg-white mb-4 pb-[100%] bg-cover bg-center"
              style={{ backgroundImage: "url('" + boat.image + "')" }}
            >
              {boat.comingsoon && (
                <span className="
                absolute top-3 right-3 text-xs bg-primary text-black p-1 px-2 rounded">
                  Coming Soon
                </span>
              )}
              <div className="absolute bottom-5 left-5 text-white">
                <h3 className="text-xl font-bold">{boat.name}</h3>
                <h4>{boat.boatModel}</h4>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}

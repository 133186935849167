import { useStaticQuery, graphql, Link, Script } from "gatsby";
import React from "react";
import Container from "../../shared/Container";
import { HEADER_MENU } from "../Header";
import FooterScripts from "./Scripts";

export default function Footer() {
  const footer = {
    items: [
      {
        name: "Terms & Conditions",
        slug: "/terms-conditions",
      },

      {
        name: "Privacy Policy",
        slug: "/privacy-policy",
      },
    ],
  };

  return (
    <>
      <footer className="bg-grey">
        <section className="hidden bg-tertiary text-white py-5 text-center">
          <Container>
            <h2 className="text-2xl font-bold">Sign up for our Newsletter</h2>

            <div>
              <p className="text-lg">Get the latest news and offers</p>

              <form className="flex justify-center items-center mt-4">
                <input
                  className="border-2 border-white rounded-l px-4 py-2"
                  type="email"
                  placeholder="Email Address"
                />
                <button
                  className="bg-dark text-white rounded-r px-4 py-2 border-2 border-dark whitespace-nowrap"
                  type="submit"
                >
                  Sign Up
                </button>
              </form>
            </div>
          </Container>
        </section>
        <section className="min-h-[200px]  py-10">
          <Container>
            <div className="flex flex-wrap md:flex-nowrap justify-between -mx-4">
              <div className="px-4 pb-8 md:pb-0">
                <h2 className="mb-2 font-bold text-xl">
                  Urban Truant Rib Club
                </h2>
                <div className="">
                  Urban Truant Power Ltd
                  <br />
                  102 Saltmakers House
                  <br />
                  Hamble Point Marina
                  <br />
                  Southampton SO31 4NB
                  <br />
                  United Kingdom
                </div>
                <div className="mt-5">
                  <a href="tel:01489250040 ">01489 250 040</a>
                  <br />
                  <a href="mailto:fun@utrib.club">fun@utrib.club</a>
                  <br />
                  Company Number: 12358415
                </div>
              </div>
              <div className="px-4 w-1/2 md:w-auto">
                <h2 className="mb-2 font-bold text-lg">Links</h2>
                <ul className="list-none m-0">
                  {HEADER_MENU.items.map((menuItem: any) => (
                    <li key={menuItem.slug}>
                      <Link
                        className="block leading-tight"
                        to={`${menuItem.slug}`}
                      >
                        {menuItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="px-4 w-1/2 md:w-auto">
                <h2 className="mb-2 font-bold text-lg">Other</h2>
                <ul className="list-none m-0">
                  {footer.items.map((item: any) => (
                    <li key={item.slug}>
                      <Link
                        className="block leading-tight"
                        to={`${item.slug}`}
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Container>
        </section>
      </footer>
      <FooterScripts />
    </>
  );
}

import React from "react";
import Container from "../../../components/shared/Container";
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import {
  DatoCmsContentBase,
  DatoCmsContentMediaBlock,
  DatoCmsListBlock,
} from "../../types";

import { StructuredText } from "react-datocms";

type Props = {
  section: DatoCmsContentBase;
};

export default function PricingTemplate({ section }: Props): JSX.Element {
  const { pricing }: {
    pricing: {
      edges: {
        node: {
          id: string;
          price: number;
          description: string;
          timing: string;
          order: number;
        }
      }[]
    }
  } = useStaticQuery(
    graphql`
      query {
        pricing: allDatoCmsPricing {
          edges {
            node {
              id
              price
              description
              timing
              order
            }
          }
        }
      }
    `
  );

  console.log("pricing", section);

  const text = section?.stringContent ? (
    <ReactMarkdown>{section?.stringContent}</ReactMarkdown>
  ) : (
    <>
      {section?.content?.value ? (
        <StructuredText data={section.content} />
      ) : null}
    </>
  );

  return (
    <Container size="max-w-screen-md">
      <div className="text-center">
        <h2 className="inline-block text-xl font-bold md:text-3xl border-b-4 mb-4 border-primary">
          {section.title}
        </h2>
      </div>
      <h3 className="text-xl md:text-3xl">{section.subTitle}</h3>
      {text ? <div className="mt-2">{text}</div> : null}

      {section.hidePrices !== true && (
        <div className="flex flex-wrap -mx-3">
          {pricing.edges
            .sort((a, b) => a.node.order > b.node.order ? 1 : -1)
            .map(({ node }, index) => {
              const priceFormatted = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "GBP",
              }).format(node.price);

              return (
                <div
                  className={`
                ${index == 0 ? "w-full text-center" : "w-full md:w-1/3"} 
                mb-3 px-3
              `}
                  key={node.id}
                >
                  <div className="border rounded p-3">
                    <h3 className="text-3xl md:text-4xl font-bold">
                      {priceFormatted}
                    </h3>
                    <div className="py-1">{node.description}</div>
                    {node.timing && <div className="text-sm">{node.timing}</div>}
                  </div>
                </div>
              );
            })}
        </div>
      )}

    </Container>
  );
}

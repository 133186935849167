import React from "react";
import type { PageProps } from "gatsby";
import { graphql } from "gatsby";

//@ts-ignore
import { HelmetDatoCms } from "gatsby-source-datocms";

import { DatoCmsPage } from "./types";
import Layout from "../components/layout";
import Container from "../components/shared/Container";
import Section from "./Section";

export default function Page({ data, pageContext }: PageProps) {
  const { id } = pageContext as any;
  const { pageData, globalImages } = data as {
    id: string;
    pageData: DatoCmsPage;
    globalImages: { nodes: { publicURL: string }[] };
  };
  const { content } = pageData;

  const images = globalImages.nodes;

  return (
    <Layout>
      <HelmetDatoCms seo={pageData.seoMetaTags} />
      {content?.map((section) => (
        <Section
          key={section.id}
          section={section}
          pageContext={pageContext}
          images={images}
        />
      ))}
    </Layout>
  );
}

export const query = graphql`
  query ($id: String!) {
    globalImages: allFile {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    pageData: datoCmsPage(id: { eq: $id }) {
      id
      name
      slug
      name

      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }

      content {
        ... on DatoCmsSectionBlock {
          id
          template
          sectionClass
          sectionId

          rowClass
          blocks {
            ... on DatoCmsContentMediaBlock {
              id
              template
              sectionClass

              title
              subTitle
              content {
                value
                blocks
              }
              imageType
              url
              linkUrl
            }
            ... on DatoCmsListBlock {
              id
              template
              sectionClass

              stringContent: content
              title
              seoMetaTags {
                tags
              }
            }
          }
        }
        ... on DatoCmsContentMediaBlock {
          id
          template
          sectionClass
          sectionId

          content {
            value
            blocks
          }
          subTitle
          title
          imageType
          url
        }
        ... on DatoCmsListBlock {
          id
          template
          sectionClass
          sectionId

          stringContent: content
          title
          seoMetaTags {
            tags
          }
        }
        ... on DatoCmsPricingBlock {
          id
          template
          sectionClass
          sectionId
          hidePrices

          title
          stringContent: content
          seoMetaTags {
            tags
          }
        }
      }
      slug
    }
  }
`;
